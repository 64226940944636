:root {
  --blue: #1082FF;
  --black: #0E1114;
  --grey: #828282;
  --grey-light: #46576B;
  --grey-light2: #F5F5F5;
  --violeta-oscuro: #421085;
  --violete-light: #DA94FF;
  --light-green: #8CF4A5;
  --orange: #FCBC10;
}

* {
  font-family: "Space Grotesk", sans-serif;
  color: var(--black);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

header .logo {
  height: 59px;
  margin-bottom: 33px;
}

body {
  background: #ffffff;
  position: relative;
  width: 100%;
  padding: 32px 0 0 0;
  min-height: 100%;
}

header, article, footer {
  padding: 0 40px;
}

header, main, footer {
  flex-shrink: 0;
}

header {
  display: flex;
  flex-direction: row;
}

header .links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

header .header-push {
  margin-left: auto;
}

header .link {
  font-size: 24px;
  padding: 10px 24px;
  border: 1px solid var(--blue);
  border-radius: 32px;
  margin-left: 18px;
}

article.main {
  height: calc(100vh - 32px - 92px);
  background: url("../img/background-art.svg") no-repeat right -500px top 30px;
}

article {
  min-height: 550px;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

article.team-section {
  margin-top: 100px;
}

article .subtitle {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

article p {
  color: var(--grey-light);
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
}

article.ending {
  height: auto;
  flex-direction: column;
  padding-top: 85px;
}

article.article-grey {
  background-color: var(--grey-light2);
}

article .left-side,
article .right-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
}

article .right-side {
  align-items: center;
  justify-items: center;
}

article .center-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 29px;
  padding-left: 80px;
  padding-right: 80px;
}

article .team {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 45px;
}

article .team h3 {
  margin-top: 15px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
}

article .team p {
  padding-bottom: 20px;
}

article .team .person {
  width: 290px;
  padding-bottom: 30px;
}

.clipped {
  clip-path: url("#msg-cloud-clip");
  height: 266px;
}

.clipped.foto-lucas {
  background-image: url("../img/fotos/lucas.jpg");
  background-size: 260px;
  background-position: -25px -40px;
}

.clipped.foto-nicolas {
  background-image: url("../img/fotos/nicolas.jpg");
  background-size: 260px;
  background-position: -5px 0;
}

.clipped.foto-ivan {
  background-image: url("../img/fotos/ivan.jpg");
  background-size: 346px;
  background-position: -40px 0;
}

.clipped.foto-catalina {
  background-image: url("../img/fotos/catalina.jpg");
  background-size: 260px;
  background-position: -5px -10px;
}

.main-text {
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
  line-height: 120px;
}

.secondary-text {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  padding-top: 30px;

  color: var(--black);
}

.main-link {
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  color: var(--blue);
  padding-top: 35px;
}

.link-arrow {
  padding-left: 10px;
  animation: 2s bounce 4s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 360deg);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-7px);
  }
  60% {
    transform: translateX(-3px);
  }
}

.highlight {
  color: var(--blue);
}

.main-text-sub {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px
}

.main-text-sub .highlight {
  font-size: 32px;
}

.secondary-pills {
  margin-top: 30px;
  margin-bottom: 40px;
  max-width: 535px;
}

.secondary-pills span {
  color: var(--grey-light);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: inline-block;
  padding: 10px 10px;
  margin-top: 10px;
  margin-right: 5px;

  border: 1px solid var(--blue);
  border-radius: 32px;
}

.contacto {
  margin-top: 200px;
  padding: 60px;
  background-color: var(--black);
  border-radius: 32px;

  color: #FFF;
  text-align: center;
  font-size: 46px;
  font-weight: 100;
  line-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacto p {
  color: #FFF;
  text-align: center;
  font-size: 46px;
  line-height: 54px;
}

.contacto a {
  display: flex;
  border-radius: 26px;
  line-height: 53px;
  padding: 0 30px;
  margin-top: 27px;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.contacto .email {
  background: var(--violete-light);
  color: var(--black);
}

.contacto .whatsapp {
  background: var(--light-green);
  color: #343434;
}

.contacto .whatsapp img {
  margin-right: 10px;
  filter: grayscale(1) brightness(0.4);
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;

  margin-top: 80px;
  padding: 0 40px;
}

.logos img {
  width: 200px;
  filter: grayscale(1);
  margin-top: 50px;
  opacity: 0.8;
}

footer {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

footer p {
  flex-grow: 1;
}

.share-icon {
  margin-left: 43px;
  line-height: 40px;
}

.mobile-only {
  display: none;
}

.link {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  color: var(--blue);
}

.credits {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;

  color: var(--grey);
}

@media only screen and (max-width: 1070px) {

  header {
    justify-content: center;
  }

  header, article, footer {
    padding: 0 30px;
  }

  article.main .right-side {
    display: none;
  }

  .main-text {
    font-size: 46px;
    line-height: 46px;
    text-align: center;
    width: 100%;
    align-self: start;
    padding: 150px 0 0;
  }

  .main-link {
    text-align: center;
  }

  .main-text-sub {
    margin-top: 30px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
  }

  article.article-toggle {
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 400px;
    min-height: auto;
    transition: height 1s ease;
  }

  article .left-side {
    padding: 0;
    position: relative;
  }

  article .right-side {
    position: absolute;
    top: 45%;
    right: 0;
    padding: 0;
    transition: right 1s ease;
  }

  article.main {
    background: none;
  }

  .chevron {
    transition: transform 1s ease;
    animation: 2s rotate 4s infinite;
  }

  article.article-open {
    height: 600px;
  }

  article.article-open .chevron {
    transform: rotate3d(1, 0, 0, 180deg);
    animation: none;
  }

  article .left-side .secondary-pills {
    position: relative;
    left: 150%;
    transition: left 1s ease;
  }

  article.article-open .left-side .secondary-pills {
    left: 0;
  }

  article.article-open .right-side {
    right: 100%;
  }

  .right-side.first-one {
    top: 50%;
  }

  article .art {
    width: 180px;
  }

  .quienes-somos-mobile {
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;

    margin-top: 80px;
    margin-bottom: 24px;
  }

  article.team-section {
    margin-top: 80px;
  }

  article .center-side {
    padding: 0;
  }

  article .team {
    flex-direction: column;
    align-items: center;
  }

  .logos {
    padding: 0;
  }

  .logos img {
    width: 120px;
  }

  .contacto {
    padding: 40px 20px 30px;
  }

  .contacto p {
    font-size: 26px;
    font-style: normal;
    line-height: 28px;
  }

  .contacto a {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  footer {
    text-align: center;
    flex-direction: column;
  }

  footer > * {
    margin-top: 40px;
  }

  .share-icon {
    margin-left: 24px;
  }

  .mobile-only {
    display: initial;
  }

  .desktop-only {
    display: none !important;
  }
}

